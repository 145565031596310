import defaultSource from "./defaultSource.js";
export default (function sourceRandomInt(source) {
  function randomInt(min, max) {
    if (arguments.length < 2) max = min, min = 0;
    min = Math.floor(min);
    max = Math.floor(max) - min;
    return function () {
      return Math.floor(source() * max + min);
    };
  }
  randomInt.source = sourceRandomInt;
  return randomInt;
})(defaultSource);