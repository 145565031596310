export default function reduce(values, reducer, value) {
  if (typeof reducer !== "function") throw new TypeError("reducer is not a function");
  const iterator = values[Symbol.iterator]();
  let done,
    next,
    index = -1;
  if (arguments.length < 3) {
    ({
      done,
      value
    } = iterator.next());
    if (done) return;
    ++index;
  }
  while (({
    done,
    value: next
  } = iterator.next()), !done) {
    value = reducer(value, next, ++index, values);
  }
  return value;
}