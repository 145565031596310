import defaultSource from "./defaultSource.js";
export default (function sourceRandomLogistic(source) {
  function randomLogistic(a, b) {
    a = a == null ? 0 : +a;
    b = b == null ? 1 : +b;
    return function () {
      var u = source();
      return a + b * Math.log(u / (1 - u));
    };
  }
  randomLogistic.source = sourceRandomLogistic;
  return randomLogistic;
})(defaultSource);