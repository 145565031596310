import defaultSource from "./defaultSource.js";
import normal from "./normal.js";
export default (function sourceRandomLogNormal(source) {
  var N = normal.source(source);
  function randomLogNormal() {
    var randomNormal = N.apply(this, arguments);
    return function () {
      return Math.exp(randomNormal());
    };
  }
  randomLogNormal.source = sourceRandomLogNormal;
  return randomLogNormal;
})(defaultSource);