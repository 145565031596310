import { blur2, max, ticks } from "d3-array";
import { slice } from "./array.js";
import constant from "./constant.js";
import Contours from "./contours.js";
function defaultX(d) {
  return d[0];
}
function defaultY(d) {
  return d[1];
}
function defaultWeight() {
  return 1;
}
export default function () {
  var x = defaultX,
    y = defaultY,
    weight = defaultWeight,
    dx = 960,
    dy = 500,
    r = 20,
    // blur radius
    k = 2,
    // log2(grid cell size)
    o = r * 3,
    // grid offset, to pad for blur
    n = dx + o * 2 >> k,
    // grid width
    m = dy + o * 2 >> k,
    // grid height
    threshold = constant(20);
  function grid(data) {
    var values = new Float32Array(n * m),
      pow2k = Math.pow(2, -k),
      i = -1;
    for (const d of data) {
      var xi = (x(d, ++i, data) + o) * pow2k,
        yi = (y(d, i, data) + o) * pow2k,
        wi = +weight(d, i, data);
      if (wi && xi >= 0 && xi < n && yi >= 0 && yi < m) {
        var x0 = Math.floor(xi),
          y0 = Math.floor(yi),
          xt = xi - x0 - 0.5,
          yt = yi - y0 - 0.5;
        values[x0 + y0 * n] += (1 - xt) * (1 - yt) * wi;
        values[x0 + 1 + y0 * n] += xt * (1 - yt) * wi;
        values[x0 + 1 + (y0 + 1) * n] += xt * yt * wi;
        values[x0 + (y0 + 1) * n] += (1 - xt) * yt * wi;
      }
    }
    blur2({
      data: values,
      width: n,
      height: m
    }, r * pow2k);
    return values;
  }
  function density(data) {
    var values = grid(data),
      tz = threshold(values),
      pow4k = Math.pow(2, 2 * k);

    // Convert number of thresholds into uniform thresholds.
    if (!Array.isArray(tz)) {
      tz = ticks(Number.MIN_VALUE, max(values) / pow4k, tz);
    }
    return Contours().size([n, m]).thresholds(tz.map(d => d * pow4k))(values).map((c, i) => (c.value = +tz[i], transform(c)));
  }
  density.contours = function (data) {
    var values = grid(data),
      contours = Contours().size([n, m]),
      pow4k = Math.pow(2, 2 * k),
      contour = value => {
        value = +value;
        var c = transform(contours.contour(values, value * pow4k));
        c.value = value; // preserve exact threshold value
        return c;
      };
    Object.defineProperty(contour, "max", {
      get: () => max(values) / pow4k
    });
    return contour;
  };
  function transform(geometry) {
    geometry.coordinates.forEach(transformPolygon);
    return geometry;
  }
  function transformPolygon(coordinates) {
    coordinates.forEach(transformRing);
  }
  function transformRing(coordinates) {
    coordinates.forEach(transformPoint);
  }

  // TODO Optimize.
  function transformPoint(coordinates) {
    coordinates[0] = coordinates[0] * Math.pow(2, k) - o;
    coordinates[1] = coordinates[1] * Math.pow(2, k) - o;
  }
  function resize() {
    o = r * 3;
    n = dx + o * 2 >> k;
    m = dy + o * 2 >> k;
    return density;
  }
  density.x = function (_) {
    return arguments.length ? (x = typeof _ === "function" ? _ : constant(+_), density) : x;
  };
  density.y = function (_) {
    return arguments.length ? (y = typeof _ === "function" ? _ : constant(+_), density) : y;
  };
  density.weight = function (_) {
    return arguments.length ? (weight = typeof _ === "function" ? _ : constant(+_), density) : weight;
  };
  density.size = function (_) {
    if (!arguments.length) return [dx, dy];
    var _0 = +_[0],
      _1 = +_[1];
    if (!(_0 >= 0 && _1 >= 0)) throw new Error("invalid size");
    return dx = _0, dy = _1, resize();
  };
  density.cellSize = function (_) {
    if (!arguments.length) return 1 << k;
    if (!((_ = +_) >= 1)) throw new Error("invalid cell size");
    return k = Math.floor(Math.log(_) / Math.LN2), resize();
  };
  density.thresholds = function (_) {
    return arguments.length ? (threshold = typeof _ === "function" ? _ : Array.isArray(_) ? constant(slice.call(_)) : constant(_), density) : threshold;
  };
  density.bandwidth = function (_) {
    if (!arguments.length) return Math.sqrt(r * (r + 1));
    if (!((_ = +_) >= 0)) throw new Error("invalid bandwidth");
    return r = (Math.sqrt(4 * _ * _ + 1) - 1) / 2, resize();
  };
  return density;
}