import defaultSource from "./defaultSource.js";
export default (function sourceRandomUniform(source) {
  function randomUniform(min, max) {
    min = min == null ? 0 : +min;
    max = max == null ? 1 : +max;
    if (arguments.length === 1) max = min, min = 0;else max -= min;
    return function () {
      return source() * max + min;
    };
  }
  randomUniform.source = sourceRandomUniform;
  return randomUniform;
})(defaultSource);