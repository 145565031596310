import { linearish } from "./linear.js";
import { copy, transformer } from "./continuous.js";
import { initRange } from "./init.js";
function transformSymlog(c) {
  return function (x) {
    return Math.sign(x) * Math.log1p(Math.abs(x / c));
  };
}
function transformSymexp(c) {
  return function (x) {
    return Math.sign(x) * Math.expm1(Math.abs(x)) * c;
  };
}
export function symlogish(transform) {
  var c = 1,
    scale = transform(transformSymlog(c), transformSymexp(c));
  scale.constant = function (_) {
    return arguments.length ? transform(transformSymlog(c = +_), transformSymexp(c)) : c;
  };
  return linearish(scale);
}
export default function symlog() {
  var scale = symlogish(transformer());
  scale.copy = function () {
    return copy(scale, symlog()).constant(scale.constant());
  };
  return initRange.apply(scale, arguments);
}