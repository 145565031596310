import continuous from "./continuous.js";
import { initRange } from "./init.js";
import { linearish } from "./linear.js";
import number from "./number.js";
function square(x) {
  return Math.sign(x) * x * x;
}
function unsquare(x) {
  return Math.sign(x) * Math.sqrt(Math.abs(x));
}
export default function radial() {
  var squared = continuous(),
    range = [0, 1],
    round = false,
    unknown;
  function scale(x) {
    var y = unsquare(squared(x));
    return isNaN(y) ? unknown : round ? Math.round(y) : y;
  }
  scale.invert = function (y) {
    return squared.invert(square(y));
  };
  scale.domain = function (_) {
    return arguments.length ? (squared.domain(_), scale) : squared.domain();
  };
  scale.range = function (_) {
    return arguments.length ? (squared.range((range = Array.from(_, number)).map(square)), scale) : range.slice();
  };
  scale.rangeRound = function (_) {
    return scale.range(_).round(true);
  };
  scale.round = function (_) {
    return arguments.length ? (round = !!_, scale) : round;
  };
  scale.clamp = function (_) {
    return arguments.length ? (squared.clamp(_), scale) : squared.clamp();
  };
  scale.unknown = function (_) {
    return arguments.length ? (unknown = _, scale) : unknown;
  };
  scale.copy = function () {
    return radial(squared.domain(), range).round(round).clamp(squared.clamp()).unknown(unknown);
  };
  initRange.apply(scale, arguments);
  return linearish(scale);
}